import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainContainer from "../Components/MainContainer";
import { ApiInstance, ApiRoutes } from "../Utils/ApiInstance";
import Modal from "../Components/Modal/Modal";
import ErrorModal from "../Components/Modal/ErrorModal";
import Header from "../Components/Header";

function Receipt() {
  const { receiptId, uuid } = useParams();
  const [isNotFound, setIsNotFound] = React.useState(false);
  const [data, setData] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [modalCode, setModalCode] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    getReceipt();
  }, []);

  const getReceipt = async () => {
    try {
      const response = await ApiInstance.get(
        `${ApiRoutes.GET_RECEIPT}/${receiptId}/${uuid}`
      );
      setData(response.data);
    } catch (error) {
      if (error.status === 404) {
        setIsNotFound(true);
      } else if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
        setModalCode(2);
        setShowModal(true);
      } else {
        setErrorMessage(error.message);
        setModalCode(2);
        setShowModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setShowModal(false);
    setModalCode(0);
    // Reset error modal
    setErrorMessage("");
  };

  const generateModalContent = () => {
    // 2: error
    if (modalCode === 2) {
      return <ErrorModal description={errorMessage} />;
    }
  };

  if (isLoading)
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center">
        Loading...
      </div>
    );

  if (isNotFound)
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center">
        <img
          src="/images/dq-logo-text-color-hr.svg"
          alt=""
          class="x-brand-logo max-w-72 mb-5"
        />
        <p className="text-2xl font-bold text-center mb-2">Receipt Not Found</p>
        <p className="text-center">
          We're sorry, but we couldn't find the receipt you were looking for.{" "}
          <br />
          Please double-check the link, or if you need further assistance,
          contact our support team.
        </p>
      </div>
    );

  return (
    <MainContainer title={"Account"}>
      <div className="bg-[#F2F2F2]">
        <div className="w-full max-w-screen-sm mx-auto py-6 px-2 sm:px-6 flex flex-col gap-6">
          <div className="flex flex-col justify-end">
            <h3 className="text-2xl text-right">Receipt</h3>
            <p className="text-right">{data?.receipt ?? "Processing..."}</p>
          </div>
          <div className="flex flex-col justify-center gap-1 pb-6 border-b border-dashed border-[#C2C2C2]">
            <img
              className="w-full max-w-[260px] mx-auto"
              src="/images/img1.png"
            />
            <p className="text-xs font-semibold text-center">
              Company Name: Foto-Zoom Sdn Bhd
            </p>
            <p className="text-xs font-semibold text-center">
              Company No. : 145663 - V
            </p>
            <p className="text-xs font-semibold text-center">
              Company Address: <br />
              11, Jalan SG 1/3 Taman Sri Gombak, 68100 Batu Caves, Selangor.
            </p>
          </div>
          <div className="px-6">
            <table className="w-full">
              <tr>
                <td className="pb-4 pr-2">Customer Name</td>
                <td className="pb-4 px-2">:</td>
                <td className="pb-4 pl-2 font-semibold">
                  {data?.customer_name ?? "-"}
                </td>
              </tr>
              <tr>
                <td className="pb-4 pr-2">Mobile No.</td>
                <td className="pb-4 px-2">:</td>
                <td className="pb-4 pl-2 font-semibold">
                  {data?.customer_phone ?? "-"}
                </td>
              </tr>
              <tr>
                <td className="pb-4 pr-2">Date / Time</td>
                <td className="pb-4 px-2">:</td>
                <td className="pb-4 pl-2 font-semibold">
                  {data?.date_time ?? "-"}
                </td>
              </tr>
              <tr>
                <td className="pb-4 pr-2">Transaction ID</td>
                <td className="pb-4 px-2">:</td>
                <td className="pb-4 pl-2 font-semibold">
                  {data?.transaction_id ?? "-"}
                </td>
              </tr>
              <tr>
                <td className="pb-4 pr-2">Order ID</td>
                <td className="pb-4 px-2">:</td>
                <td className="pb-4 pl-2 font-semibold">
                  {data?.order_id ?? "-"}
                </td>
              </tr>
              <tr>
                <td className="pb-4 pr-2">Outlet ID</td>
                <td className="pb-4 px-2">:</td>
                <td className="pb-4 pl-2 font-semibold">
                  {data?.outlet_id ?? "-"}
                </td>
              </tr>
              <tr>
                <td className="pr-2">Channel</td>
                <td className="px-2">:</td>
                <td className="pl-2 font-semibold">{data?.channel ?? "-"}</td>
              </tr>
            </table>
          </div>
          <div
            className="bg-['#EFEFEF'] flex flex-col gap-6 rounded-3xl py-10 px-3 sm:px-6 overflow-auto"
            style={{ boxShadow: "0px 0px 10px 0px #5B5B5B40" }}
          >
            <h4 className="text-xl font-bold">Payment Summary</h4>
            <table className="w-full">
              <tr>
                <th className="pb-4 pr-2 text-left font-semibold">Code</th>
                <th className="pb-4 px-2 text-left font-semibold">
                  Product Description
                </th>
                <th className="pb-4 px-2 text-left font-semibold">Quantity</th>
                <th className="pb-4 px-2 text-left font-semibold">
                  Unit Price
                  <br />
                  (RM)
                </th>
                <th className="pb-4 px-2 text-left font-semibold">
                  Total
                  <br />
                  (RM)
                </th>
                <th className="pb-4 pl-2 text-left font-semibold">Tax Code</th>
              </tr>
              {data?.payment_summary?.length > 0 &&
                data?.payment_summary?.map((item, i) => (
                  <tr key={i}>
                    <td className="pb-6 pr-2">{item.code}</td>
                    <td className="pb-6 px-2">
                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </td>
                    <td className="pb-6 px-2">{item.quantity}</td>

                    <td className="pb-6 px-2">
                      {parseFloat(item.unit_price).toFixed(2)}
                    </td>
                    <td className="pb-6 px-2">
                      {parseFloat(item.total).toFixed(2)}
                    </td>
                    <td className="pb-6 pl-2">-</td>
                  </tr>
                ))}
              <tr className="border-t border-dashed border-[#C2C2C2]">
                <td colSpan={4} className="pt-6 pb-2 pr-2 text-right">
                  Subtotal
                </td>
                <td colSpan={2} className="pt-6 pb-2  pl-2">
                  {parseFloat(data?.subtotal).toFixed(2)}
                </td>
              </tr>

              {/* PUD */}
              {data?.type === "order" && (
                <tr>
                  <td colSpan={4} className="py-2 pr-2 text-right">
                    Shipping
                    <br />
                    Amount
                  </td>
                  <td colSpan={2} className="py-2 pl-2">
                    {parseFloat(data?.shipping_amount).toFixed(2)}
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan={4} className="py-2 pr-2 text-right">
                  Rounding
                  <br />
                  Adjustment
                </td>
                <td colSpan={2} className="py-2 pl-2">
                  {parseFloat(data?.rounding_adjustment).toFixed(2)}
                </td>
              </tr>
              <tr className="bg-[#D9D9D9]">
                <td colSpan={4} className="py-2 pr-2 text-right">
                  Total Amount
                </td>
                <td colSpan={2} className="py-2 pl-2 font-bold">
                  {parseFloat(data?.total).toFixed(2)}
                </td>
              </tr>
            </table>
            <p>
              Payment Method:{" "}
              <span className="font-bold">{data?.payment_method ?? "-"}</span>
            </p>
          </div>
          <div className="flex flex-col justify-center gap-3">
            <img
              className="w-full max-w-[300px] mx-auto"
              src="/images/img2.png"
            />
            <p className="text-xl font-bold text-center">Disclaimer</p>
            <p className="text-xs font-semibold text-center">
              All transactions including topups are non-transferable,
              non-refundable in cash.
            </p>
            <p className="text-xs font-semibold text-center">
              E-Invoice is available upon request within 24 hours from the date
              of purchase @ www.dobiqueen.my
            </p>
            <p className="text-xs font-semibold text-center">
              Please keep this receipt.
            </p>
            <p className="text-xs font-semibold text-center">
              Thank you! Please come again.
            </p>
          </div>
        </div>
      </div>
      <Modal
        showModal={showModal}
        handleCloseModal={(e) => handleCloseModal(e)}
        content={generateModalContent()}
        button={
          <a
            onClick={(e) => handleCloseModal(e)}
            className="x-btn for-secondary is-close-modal w-button"
          >
            Dismiss
          </a>
        }
      />
    </MainContainer>
  );
}

export default Receipt;
